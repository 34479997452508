<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Skill sets">
      <template v-slot:actions>
        <div class="d-flex justify-end gap-10">
          <v-btn
            @click="getSkillSets()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn @click="dialog = true" color="accent gradient">
            Add New
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <div>
      <preloader v-if="status.getting" />

      <v-text-field
        v-model="search"
        v-if="!status.getting"
        placeholder="Search"
        append-icon="mdi-magnify"
        clearable
        outlined
        dense
      ></v-text-field>

      <custom-alert v-if="!skills.length">
        No skillset found.
      </custom-alert>

      <v-card v-if="skills && skills.length && !status.getting" outlined>
        <v-data-table
          :headers="headers"
          :items-per-page="15"
          :items="skills"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-btn @click="editItem(item)" small icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            
            <v-btn @click="showDeleteDialog(item)" small icon>
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <!-- ADD/EDIT DIALOG -->
    <v-dialog
      v-model="dialog"
      :max-width="$dialog.small"
      :persistent="status.adding"
    >
      <v-card>
        <v-card-title class="font-weight-black primary--text">
          {{ data.id ? 'Update skill set' : 'Add new skill set' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <v-text-field
              v-model="data.name"
              :rules="[rules.required]"
              label="Name"
              outlined
              dense
            ></v-text-field>
          </v-form>

          <div class="d-flex align-center gap-10">
            <v-btn @click="validateForm()" :loading="status.adding" color="accent gradient">
              {{ data.id ? 'Update' : 'Add' }}
            </v-btn>
            <v-btn @click="closeDialog()" :disabled="status.adding" text>Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :message="`Delete skillset '${toDelete.name}'?`"
      :deleting="status.deleting"
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import _orderBy from 'lodash/orderBy'

export default {
  metaInfo: {
    title: 'Manage Skillsets'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
      search: null,
      deleteDialog: false,
      toDelete: {},
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.skillSets.status,
      skillsets: (state) => state.skillSets.skillSets,
      data: (state) => state.skillSets.data,
    }),

    skills: function () {
      let refined = this.skillsets

      if (this.search) {
        refined = refined.filter(skill => {
          return skill.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      
      return _orderBy(refined, (skill) => skill.name.toLowerCase(), 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('skillSets', [
      'getSkillSets',
      'addSkillset',
      'deleteSkillset',
      'updateSkillset'
    ]),

    editItem(item) {
      this.$store.commit('skillSets/setData', item)
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.$store.commit('skillSets/setData', {})
      this.$refs.addForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.addForm.validate()) {
        let task

        if (this.data.id) task = Promise.all([this.updateSkillset()])
        else task = Promise.all([this.addSkillset()])
        
        task.then(() => {
          this.closeDialog()
        })
      }
    },

    closeDeleteDialog() {
      this.deleteDialog = false
      this.toDelete = {}
    },

    showDeleteDialog(skill) {
      this.toDelete = Object.assign({}, skill)
      this.deleteDialog = true
    },
    
    deleteConfirmed() {
      Promise.all([this.deleteSkillset(this.toDelete)])
      .then(() => { this.closeDeleteDialog() })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getSkillSets()
  }
}
</script>